<template>
  <div class="roles_container">
    <el-breadcrumb separator-class="el-icon-arrow-right" class="breadTitle">
      <el-breadcrumb-item>系统设置</el-breadcrumb-item>
      <el-breadcrumb-item>用户管理</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <el-table :data="tableData" style="width: 100%" class="tableStyle">
        <el-table-column fixed prop="account" label="账户" width="150"></el-table-column>
        <el-table-column prop="nickname" label="昵称" width="120"></el-table-column>
        <el-table-column prop="remark" label="简介" width="120"></el-table-column>
        <el-table-column prop="role" label="角色"></el-table-column>
        <el-table-column fixed="right" label="操作" width="200" class="tableEdit">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="编辑" placement="top">
              <i class="el-icon-edit" @click="handleClick(scope.row)"></i>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="预览" placement="top">
              <i class="el-icon-view"></i>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="删除" placement="top">
              <i class="el-icon-delete"></i>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
export default {
  // 组件名称
  name: 'Rights',
  data() {
    return {
      tableData: [
        {
          account: '185666748',
          nickname: '张三',
          remark: '临时添加',
          role: '管理员'
        },
        {
          account: '188466748',
          nickname: '李四',
          remark: '临时添加',
          role: '普通用户'
        }
      ]
    }
  },
  mounted() {},
  // 组件方法
  methods: {
    handleClick(row) {
      console.log(row)
    }
  }
}
</script>

<style lang="scss" scoped>
.roles_container {
  height: 100%;
  color: #fff;
  .aside_title {
    box-sizing: border-box;
    height: 70px;
    text-align: center;
    line-height: 70px;
    border-bottom: 2px solid rgba(100, 97, 97, 0.8);
    i {
      font-size: 18px;
      padding-right: 10px;
    }
  }
  .home_title {
    position: absolute;
    box-sizing: border-box;
    bottom: 0;
    width: 180px;
    height: 70px;
    padding: 0 20px;
    line-height: 70px;
    border-top: 2px solid rgba(100, 97, 97, 0.8);
    i {
      font-size: 18px;
      padding-right: 4px;
    }
  }
  .tableStyle {
    color: #fff;
    .item {
      margin-right: 10px;
    }
  }
}
</style>
